import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'
import client3 from '../../assets/images/testimonials/client3.jpg'
import icon1 from '../../assets/images/service-icons/icon-1.png'
import icon2 from '../../assets/images/service-icons/icon-2.png'
import icon3 from '../../assets/images/service-icons/icon-3.png'
import icon4 from '../../assets/images/service-icons/icon-4.png'
import icon5 from '../../assets/images/service-icons/icon-5.png'
import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="Service-area">
            <div className="container pt-100">
                <div className="section-title">
                    {/* <span className="sub-title">
                        <img src={starIcon} alt="testimonial" /> 
                        Testimonials
                    </span> */}
                    <h2>How we can help you</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                </div>

                {/* {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                    <div className="single-testimonials-item">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client1} alt="testimonial" />
                                <div className="title">
                                    <h3>Alex Maxwell</h3>
                                    <span>CEO at EnvyTheme</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client2} alt="testimonial" />
                                <div className="title">
                                    <h3>David Warner</h3>
                                    <span>CEO at Envato</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client3} alt="testimonial" />
                                <div className="title">
                                    <h3>Sarah Taylor</h3>
                                    <span>CEO at ThemeForest</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''} */}

                <div className="container service-cards">
                    <div className="row row-cols-1 row-cols-md-2">           

                        <div className="col align-self-center">
                            <div className="row justify-content-around justify-content-md-around">
                                <div className="card w-75 p-4">
                                    <div className="card-body">
                                    <img src={icon2} alt="service" />
                                    <h3 className="card-title pt-5">Mobile Apps</h3>
                                    <p className="card-text">We help you to establish your mobile strategy and 
                                        implement unique solutions for B2B, B2C and B2E. 
                                        These solutions often need business rules, business 
                                        intelligence, integration with other systems and to 
                                        be able to leverage latest tech</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-around">
                                <div className="card w-75 p-4">
                                    <div className="card-body">
                                    <img src={icon4} alt="service" />
                                    <h3 className="card-title pt-5">Automation Testing</h3>
                                    <p className="card-text">We provide Testing Services for all our development 
                                        engagements. In certain cases, we also provide 
                                        dedicated testing services for products. We are also 
                                        engaged in niche testing needs like API testing, 
                                        IoT devices testing, and load testing.</p>
                                    </div>
                                </div>
                            </div>              
                        </div>

                        <div className="col">  
                            <div className="row justify-content-around justify-content-md-around">  
                                <div className="card w-75 p-4">
                                    <div className="card-body">
                                    <img src={icon1} alt="service" />
                                    <h3 className="card-title pt-5">Software Development</h3>
                                    <p className="card-text">Software application development and product 
                                        innovation services are at the core of our business. 
                                        Creating integrated and unified architectures, we help 
                                        startups, SME and large enterprises in their product 
                                        innovation, evolution.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-around justify-content-md-around">
                                <div className="card w-75 p-4">
                                    <div className="card-body">
                                    <img src={icon3} alt="service" />
                                    <h3 className="card-title pt-5">IOT Devices</h3>
                                    <p className="card-text">We help organisations in this domain, by providing 
                                        services in remote sensing, operations monitoring, 
                                        asset tracking and predictive maintenance. </p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row justify-content-around">
                                <div className="card w-75 p-4">
                                    <div className="card-body">
                                    <img src={icon5} alt="service" />
                                    <h3 className="card-title pt-5">OSS/BSS</h3>
                                    <p className="card-text">Communications service providers support a broad 
                                        range of services and functions with their OSS/BSS. 
                                        BSS primarily consists of order capture, Customer 
                                        Relationship Management and Telecommunications 
                                        billing whereas OSS covers Order Management, 
                                        Network Inventory Management and Network Operations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                {/* <div className="testimonials-view-btn text-center">
                    <Link to="/testimonials" className="default-btn">
                        <i className="flaticon-view"></i> 
                        Check Out All Reviews <span></span>
                    </Link>
                </div> */}
            </div>

            {/* <div className="shape-img1">
                <img src={shape} alt="testimonial" />
            </div> */}
        </div>
    )
}

export default Testimonials;