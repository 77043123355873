import React from 'react'
import {Link} from 'gatsby'
import nokiaLogo from '../../assets/images/partner/nokia-logo.png'
import vodacomLogo from '../../assets/images/partner/vodacom-logo.png'
import vodafoneLogo from '../../assets/images/partner/vodafone-logo.png'

const ProjectStartArea = () => {
    return (
        <div className="project-start-area">
            <div className="container">
                
                <div className="text-center">
                    <div className="section-title">     
                        <h2>Our Innovation Partners</h2>
                    </div>
                    <div className="row pt-md-4">                 
                        <div className="col-sm-12 col-md-4 d-flex justify-content-around justify-content-md-end pb-4">
                            <div className="py-2 d-flex justify-content-around w-75">
                                <img src={nokiaLogo} alt="partner" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 d-flex justify-content-around pb-4">
                            <div className="py-2 d-flex justify-content-around w-75">
                                <img src={vodacomLogo} alt="partner" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 d-flex justify-content-around justify-content-md-start pb-4">
                            <div className="py-2 d-flex justify-content-around w-75">
                                <img src={vodafoneLogo} alt="partner" />
                            </div>
                        </div>
                    </div>
                </div>
                    
                <div className="project-start-content">
                    <div className="row pt-5 pb-5">
                        <p className="col-12 col-lg-8 text-lg-left text-center pl-lg-5">We innovate across multiple industries and service sectors, partnering with our clients to deliver solutions that serve their various domains.</p>                           
                        <div className="col-12 col-lg-4 d-lg-flex justify-content-lg-end align-self-lg-start pr-lg-5">
                            <Link to="/contact" className="default-btn">
                                {/* <i className="flaticon-web"></i>  */}
                                Learn More 
                                <span></span>
                            </Link>
                        </div>
                    </div>
                </div>                   
                
            </div>

        </div>
    )
}

export default ProjectStartArea;